var $ = require ('jquery');
var skrollr = require ('skrollr');
var nanoScroller = require ('nanoscroller');
var moment = require ('moment');


// Constants for keyboard events
var UP = 38
var DOWN = 40
var SPACE = 32

/***
 *  Navigation methods: prev/next/arbitrary selection
 ***/

function prev_speaker() {
  li = $('article.speaker.selected').prev()
  if ( li.length == 0 || li.attr('id') == 'welcome' )
    li = $('article.speaker').last()

  select_speaker( li.attr('id'), true )
}


function next_speaker() {
  li = $('article.speaker.selected').next()
  if ( li.length == 0 )
    li = $('article.speaker').first()

  select_speaker( li.attr('id'), true )
}


function select_speaker( speaker_id, push_state ) {
  var push_state = push_state || false

  nav_li = $('#' + speaker_id + '_nav')
  speaker = $('#' + speaker_id)

  var inWidth = $('body').innerWidth() // window width not including scrollbars
  if (inWidth <= 768) { // Mobile/small
    skr.setScrollTop( speaker.offset().top )
  } else { // Desktop/large
    adjust_speaker_size( speaker )
  }

  if ( nav_li.length == 0 || speaker.length == 0 ) {
    console.log( "Invalid speaker_id in select_speaker!" )
    return false
  }

  $('article.selected').addClass('current').removeClass('selected')
  $('nav.speaker_nav ul li.selected').removeClass('selected')

  nav_li.addClass('selected')
  speaker.addClass('selected')

  if ( push_state ) {
    // Update page title, history, url
    var new_title = speaker.data('fullname')
    var new_url = speaker.data('url')
    // window.location.hash = new_url;
    // window.history.pushState(new_url, new_title, '/' + new_url)
  }

  window.setTimeout(function(){
    $('article.current').removeClass('current')
    $('a:hover').addClass("nohover")
  }, 750)
}


function welcome( push_state ) {
  // var push_state = push_state || false

  speaker = $('#welcome')

  var inWidth = $('body').innerWidth() // window width not including scrollbars
  if (inWidth <= 768) { // Mobile/small
    $('body').prop('scrollTop', speaker.offset().top)
  }

  $('article.selected').addClass('current').removeClass('selected')
  $('nav.speaker_nav ul li.selected').removeClass('selected')

  speaker.addClass('selected')

  // if ( push_state ) {
  //   // Update page title, history, url
  //   var new_title = 'Welcome'
  //   var new_url = ''
  //   window.history.pushState(new_url, new_title, '/' + new_url)
  // }

  window.setTimeout(function(){
    $('article.current').removeClass('current')
    $('a:hover').addClass("nohover")
  }, 750)
}


function adjust_speaker_size( article ) {

  if ( !article.hasClass('speaker') ) // Nothing to adjust for the welcome slide
    return false

  var inWidth = $('body').innerWidth() // window width not including scrollbars

  if ( inWidth <= 768 )
    return false // No adjustment necessary for mobile screens

  var inner = article.find('.right .inner')
  var iTop = inner.find('.top')
  var iBottom = inner.find('.bottom')
  var bio = inner.find('.bio')

  var iHeight = inner.height()
  var topHeight = iTop.outerHeight(true)
  var bottomHeight = iBottom.outerHeight(true)
  var leftover = iHeight - topHeight - bottomHeight

  var naturalHeight = bio.find('.nano-content').prop('scrollHeight')

  if ( leftover > naturalHeight )
    leftover = naturalHeight

  bio.css('height', leftover + 'px')
  bio.nanoScroller({ disableResize: true, alwaysVisible: true, iOSNativeScrolling: false })

  return true
}


// Simple animation effect on first load
function reveal_page() {
  $('#global').css({
    'bottom': ((window.innerHeight - 67) + 'px'),
    'top': '0px'
  }) // Animates with CSS transition.

  // Animating 'bottom' seems to work better than animating 'height'.
  // But leaving the height + bottom as-is will mess things up if the window
  // is resized. Swapping the fixed bottom out for a height *after*
  // the animation is done solves that resize problem

  window.setTimeout(function(){
    $('#global').css({
      'bottom': 'auto',
      'height': '64px'
    })
  }, 2000)
}


function speaker_padding() {
  var inHeight = window.innerHeight
  return window.innerHeight - 165
}


function add_speaker_padding() {
  var inWidth = $('body').innerWidth() // window width not including scrollbars
  var topPad = speaker_padding()

  // FIXME get this on resize too
  if (inWidth <= 768) { // Small/mobile screens
    $('.speaker-content').css('padding-top', topPad + 'px')
    $('.speaker-content').css('background-size', 'auto ' + ( topPad + 100 ) + 'px' )
  }
  else {
    $('.speaker-content').css('padding-top', '0px')
  }
}


function init_navigation_events() {
  // Enable nav item click handler
  $('nav.speaker_nav ul li').on('touchend, click', function(evt){
    nav_li = $(evt.target).closest('li');
    speaker_id = nav_li.data('article-id');

    select_speaker( speaker_id, true )
    evt.preventDefault()
  })

  // Enable keyboard navigation (up, down)
  $(document).keyup(function(evt){
    if (evt.which == UP)
      prev_speaker()
    else if (evt.which == DOWN || evt.which == SPACE)
      next_speaker()
    return false
  })

  // Allow arrows and spaces in a form without navigating.
  $('form').keyup(function(evt){
    evt.stopPropagation()
  })


  // Wire up 'Book' button click events
  $('a.button.book').click(function(evt) {
    tgt = $(evt.target).closest('a')
    target_selector = $(tgt).data('target')
    $(target_selector).addClass('active')
    evt.stopPropagation()
  })


  $('a.close').click(function(evt) {
    $('.modal.active').removeClass('active')
    $('.modal form input').val('')
    $('.modal-dialog.thanks').hide()
    $('.modal-dialog.sorry').hide()
    $('.modal-dialog.form').show()
  })


  $('.modal-dialog').click(function(evt){
    evt.stopPropagation()
  });


  $(document).click(function(evt){
    modal = $('.modal.active')
    if ( modal.length > 0 )
      modal.removeClass('active')
    return true
  })


  window.onpopstate = function(event) {
    if ( event.state ) {
      select_speaker( event.state, false ) // don't re-push the state we just popped
    }
    else {
      welcome(false)
    }
    return true
  };
}


function send_request_email(speaker_id) {

  var speaker = $('#' + speaker_id)
  var form = $('#' + speaker_id + '_form')
  var speaker_name = speaker.data('fullname')

  var submitter_name = form.find('input[name=name]').val()
  var submitter_phone = form.find('input[name=phone]').val()
  var submitter_email = form.find('input[name=email]').val()
  var submitter_date = form.find('input[name=date]').val()

  form.find('.errors').empty()
  if(submitter_name == "") {
    form.find('.errors').append("<li>Name is required.</li>")
    return false
  }
  if(submitter_email == "") {
    form.find('.errors').append("<li>Email is required.</li>")
    return false
  }
  if(!validate_email(submitter_email)) {
    form.find('.errors').append("<li>Email address is invalid.</li>")
    return false
  }
  if(submitter_phone == "") {
    form.find('.errors').append("<li>Phone Number is required.</li>")
    return false
  }

  var mailresponse = $.get("https://mandrillapp.com/api/1.0/messages/send.json", {
    key: "rWZNVMubtM7YDO7GeoCymQ",
    message: {
      subject: "Speaker Request - " + speaker_name,
      text: "Submitted: " + moment().format("MMMM Do YYYY, h:mm a") + "\r\n"
            + "Requeseted Speaker: " + speaker_name + "\r\n\r\n"
            + "Requested By:\r\n\tName: " + submitter_name + "\r\n\r\n"
            + "\tPhone: " + submitter_phone + "\r\n"
            + "\tEmail: " + submitter_email + "\r\n"
            + "\tDate: " + submitter_date + "\r\n",
      from_email: "speakers@successpartners.com",
      from_name: "SUCCESS Speaker Services",
      to: [{ email: "dan@lightsandcolors.com", name: "Dan Hilton", type: "to"}],
      async: true
    }
  }, function(data, text_status, xhr) {
    form.find('.form').hide()
    if ( text_status == 'success' ) {
      form.find('.thanks').show()
    }
    else {
      form.find('.sorry').show()
    }
  });
}


function validate_email(addr) {
  var re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
  return re.test(addr);
}


function scroll_to_speaker() { // from URL
  var url = window.location.pathname
  var slashindex = url.lastIndexOf('/')
  url = url.slice( slashindex + 1 )
  if ( url != '' && url != 'index.html' ) {
    select_speaker( url, false )
  }
}


function change_orientation(orientation) {
  var inWidth = $('body').innerWidth() // window width not including scrollbars

  if ( Math.abs(orientation) == 90 ) { // Landscape
    // Make sure the currently-scrolled-to speaker is selected
    if ( inWidth > 768 ) {
      select_speaker( window.current_speaker_id, true )
    }
  } else { // Portrait
    if ( inWidth <= 768 ) {
      window.setTimeout( scroll_to_speaker, 10 )
    }
    // no-op?
  }
}


function keyframe_callback( el, name, dir ) {
  if ( ( name == 'data65Top' && dir == 'down' ) || (name == 'dataPadTop' && dir == 'up' ) )  {
    window.current_speaker_id = $(el).closest('article').attr('id')
    //console.log( "Scrolled to " + $(el).closest('article').data('fullname') )
  }
}

$(document).ready(function() {

  // Make sure speaker from url is selected
  window.setTimeout( scroll_to_speaker, 2000 )

  add_speaker_padding()

  reveal_page()

  adjust_speaker_size($('article.speaker.selected'))

  init_navigation_events()

  //
  // Make skrollr + nano scrolling play nice on iOS/disable overscroll
  //

  $(document).on('touchmove', function(e) {
      e.preventDefault();
  })

  $(".bio .nano-content").on('touchstart', function(e) {
    this.allowUp = (this.scrollTop > 0);
    this.allowDown = (this.scrollTop < this.scrollHeight - this.clientHeight);
    this.prevTop = null;
    this.prevBot = null;
    this.lastY = e.originalEvent.pageY;
  }).on('touchmove', function(e) {
    var event = e.originalEvent;
    var up = (event.pageY > this.lastY), down = !up;
    this.lastY = event.pageY;

    if ((up && this.allowUp) || (down && this.allowDown)) {
      event.stopPropagation();
    }
    else {
      event.preventDefault();
    }
  })

  $(".bio").on('touchstart touchmove touchend', function(e) {
    var inWidth = $('body').innerWidth() // window width not including scrollbars
    if (inWidth > 768) { // Desktop/large
      e.stopPropagation()
    }
  })


  $(window).resize(function(){
    var sel = $('article.speaker.selected')
    adjust_speaker_size(sel)
    add_speaker_padding()
  })

  $(window).on("orientationchange", function(evt){
    change_orientation( window.orientation )
  })

  // Skrollr for paralax effect on mobile
  window.setTimeout( function(){
    skr = skrollr.init( { keyframe: keyframe_callback, smoothScrolling: false, forceHeight: false, constants: { pad: function(){ return speaker_padding() } } } );
  }, 300 )


  $('.js-next-speaker').on('click', function(e){
    $(this).removeClass('nohover');
    next_speaker();
     e.preventDefault();
  });


  $('.js-welcome').on('click', function(e){
    welcome(false);
    e.preventDefault();
  });

});